.evgentest .Mui-selected {
  background-color: #036deb;
  /* color: white !important; */
  /* margin: 10px 0; */
  /* padding: 10px; */
}

.evgentest .Mui-selected p {
  background-color: #036deb;
  color: white !important;
  /* margin: 10px 0; */
  padding: 10px 0;
}

.evgentest svg {
  color: rgb(77, 142, 77) !important;
}

.evgentest .Mui-selected svg {
  color: rgb(77, 142, 77) !important;
  background-color: white !important;
  border-radius: 10px;
  border: 0px;
}