.MuiButtonBase-root.MuiToggleButton-root.MuiToggleButtonGroup-grouped.MuiToggleButtonGroup-grouped_horizontal {
  display: flex;
  align-content: flex-start !important;
  flex-wrap: wrap;
  width: 50px;
  padding: 8px
}

.MuiToggleButton-root.Mui-selected, .MuiToggleButton-root:hover {
  background-color: unset !important;
}

.agreementTile {
  width: 43px;
  height: 43px;
  padding: 10px;
  gap: 4px;
  border-radius: 4px;
  /* opacity: 0px; */
  background-color: #f5f6f8;
}

.MuiToggleButton-root.Mui-selected .agreementTile, .MuiToggleButton-root:hover .agreementTile {
  background-color: #036deb;
  transition: 0.3s;
  opacity: 1;
  color: white;
}

.MuiToggleButton-root:hover {
  color: #036deb;
}
